import React from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../../components/common/Layout'
import { SEO } from '../../components/common/SEO'
import { NewsHeader, NewsIndex } from '../../components/domains/news'

const NewsPage = (props: PageProps) => {
	return (
		<Layout location={[{ name: 'ニュース', path: '/news' }]}>
			<SEO title="ニュース" description="" />
			<NewsHeader location={props.location} />
			<NewsIndex />
		</Layout>
	)
}

export default NewsPage
